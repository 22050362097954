<template>
    <div class="pagedetails">
        <div class="pagedetails-wrap">
            <div class="pagedetails-wrap-top">
                <p class="title">{{ contentdetail.title }}</p>
                <p class="time">发布日期:{{ contentdetail.create_time }}</p>
                <p v-html="contentdetail.long_contents"></p>
                <!-- <p><img :src="contentdetail.cover_imgurl" alt="" /></p> -->
            </div>
        </div>
    </div>
</template>
<style lang="scss">
    .pagedetails {
        width: 100%;
        height: 100%;

        .my-top {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0.3rem;
            box-sizing: border-box;
            font-size: 0.38rem;
            background: #f4f4f4;
        }

        .pagedetails-wrap {
            padding-left: 0.3rem;
            padding-right: 0.26rem;
            font-size: 0.28rem;
            box-sizing: border-box;

            .pagedetails-wrap-top {
                width: 100%;

                .title {
                    text-align: center;
                    font-size: 0.26rem;
                    color: #242424;
                    margin-bottom: 0.3rem;
                    margin-top: 0.56rem;
                }

                .time {
                    text-align: center;
                    font-size: 0.21rem;
                    color: #bbbbbb;
                    margin-bottom: 0.62rem;
                }

                // p:nth-child(3) {
                //   font-size: 0.24rem;
                //   color: #242424;
                //   margin-bottom: 0.3rem;
                // }
                img {
                    width: 100% !important;
                }
            }
        }
    }
</style>
<script>
    import {
        ContentDetail
    } from "@/http/api";
    export default {
        data() {
            return {
                news_id: this.$route.query.id,
                contentdetail: {}
            };
        },
        mounted() {
            this.ContentDetail();
        },
        methods: {
            async ContentDetail() {
                const res = await ContentDetail({
                    data: {
                        news_id: this.news_id
                    }
                });
                console.log(res);
                this.contentdetail = res.data;
            }
        },
        created() {}
    };
</script>
